import { getPagination } from '@api/common/helpers';

import { CalcFeaturesIcon } from '@icons/components/CalcFeaturesIcon';
import { LaunchIcon } from '@icons/components/LaunchIcon';
import { ProjectIcon } from '@icons/components/ProjectIcon';
import SpecialPriceIcon from '@icons/components/SpecialPriceIcon';

export const featuresButtons = [
    {
        title: (
            <>
                Просчет <br />
                спецификаций
            </>
        ),
        text: <>Автоматический подбор оборудования</>,
        Icon: ({ isWhiteColor }: { isWhiteColor: boolean }) => <CalcFeaturesIcon isWhiteColor={isWhiteColor} />,
        link: '/pages/specs',
        id: 'CalcFeaturesIcon',
    },
    {
        title: (
            <>
                Специальные цены для <br />
                юридических лиц и ИП
            </>
        ),
        text: (
            <>
                Регистрируйтесь и получайте <br />
                отдельную колонку цен
            </>
        ),
        Icon: ({ isWhiteColor }: { isWhiteColor: boolean }) => <SpecialPriceIcon isWhiteColor={isWhiteColor} />,
        id: 'SpecialPriceIcon',
    },
    {
        title: (
            <>
                Заказать <br />
                проектирование
            </>
        ),
        text: (
            <>
                Разработка и защита <br /> проекта
            </>
        ),
        Icon: ({ isWhiteColor }: { isWhiteColor: boolean }) => <ProjectIcon isWhiteColor={isWhiteColor} />,
        link: '/pages/projecting',
        id: 'ProjectIcon',
    },
    {
        title: <>Заказать монтаж оборудования</>,
        text: <>Проверенные монтажные организации</>,
        Icon: ({ isWhiteColor }: { isWhiteColor: boolean }) => <LaunchIcon isWhiteColor={isWhiteColor} />,
        link: '/pages/mounting',
        id: 'LaunchIcon',
    },
];

export const CAROUSEL_ITEMS = 12;
export const searchParams = {
    sort: 'id',
    pagination: getPagination(CAROUSEL_ITEMS),
    include: ['brand', 'category', 'images', 'attributes', 'seller'],
};
